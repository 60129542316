import React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import { addClassName } from "../../utils"

const FIRST_PAGE = 6
const LAST_PAGE = 350

function PageButton({ pageNum, text, ...props}) {
  const isDisabled = (pageNum < FIRST_PAGE || pageNum > LAST_PAGE)
  props = addClassName("btn btn-lg btn-primary btn-page-nav shadow", props)
  return (
    isDisabled
      ? <Button disabled {...props}>{ text }</Button>
      : <Link to={`/book/p${pageNum}`} {...props}>{ text }</Link>
  )
}

function PrevPageButton({ pageNum, ...props }) {
  return <PageButton pageNum={pageNum} text="‹" {...addClassName("prev", props)} />
}

function NextPageButton({ pageNum, ...props }) {
  return <PageButton pageNum={pageNum} text="›" {...addClassName("next", props)} />
}

function PageInput({ pageNum, ...props }) {
  props = addClassName("page-num-input text-center border-primary shadow", props)
  return (
    <Form.Control
      placeholder={pageNum}
      type="number"
      size="lg"
      min={FIRST_PAGE}
      max={LAST_PAGE}
      aria-label="pagina"
      aria-describedby="basic-addon1"
      style={{ width: "5rem", MozAppearance: "textfield" }}
      {...props} />
  )
}

export default function BookNavbar({ page }) {
  const pageNum = parseInt(page.page_num)
  const [validated, setValidated] = React.useState(false);
  const handlePageNumFormSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === true) {
      setValidated(true);
      const newPageNum = parseInt(
        form.getElementsByClassName('page-num-input')[0].value)
      navigate(`/book/p${newPageNum}`)
    }
  }
  return (
    <Navbar className="mb-3" fixed="bottom">
      <Container className="justify-content-center">
        <Form noValidate 
          validated={validated} 
          onSubmit={handlePageNumFormSubmit}
          className="d-flex">
          <PrevPageButton pageNum={pageNum - 1} className="mr-4" />
          <PageInput pageNum={pageNum} />
          <NextPageButton pageNum={pageNum + 1} className="ml-4" />
        </Form>
      </Container>
    </Navbar>
  )
}