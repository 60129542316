import React from "react"
import { graphql } from 'gatsby'

import BookLayout from "../components/book/book-layout"
import BookNavbar from "../components/book/book-navbar"
import BookPage from "../components/book/book-page"
import { Swipeable } from "react-swipeable"
import { navigate } from "gatsby"

import "../styles/main.scss"

const FIRST_PAGE = 6
const LAST_PAGE = 350

export default ({ data }) => {
  // console.log(data)

  // Swipe navigation
  const pageNum = parseInt(data.page.page_num)
  const swipeThreshold = 50
  const handleSwipedRight = ({ deltaX }) => {
    console.log(deltaX)
    if(pageNum > FIRST_PAGE & Math.abs(deltaX) > swipeThreshold) 
      navigate(`/book/p${pageNum - 1}`)
  }
  const handleSwipedLeft = ({ deltaX }) => {
    if(pageNum < LAST_PAGE & deltaX > swipeThreshold) 
      navigate(`/book/p${pageNum + 1}`)
  }
  return (
    <Swipeable onSwipedLeft={handleSwipedLeft} onSwipedRight={handleSwipedRight}>
      <BookLayout>
        <BookPage page={data.page} />
        <BookNavbar page={data.page} />
      </BookLayout>
    </Swipeable>
  )
}

export const objectProperties = graphql`
  fragment objectProperties on Object {
    id
    object_id
    code
    collaborator
    genres {
      genre_id
      title
    }
    country
    comments
    artist
    description
    exhibition
    latitude
    longitude
    zoom
    project
    tags
    title
    type
    year
    image: file {
      childImageSharp {
        fluid(maxWidth: 1500) {
          presentationHeight
          presentationWidth
          ...GatsbyImageSharpFluid
          # ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

export const query = graphql`
  query ($page_num: String!) {
    page(page_num: {eq: $page_num}) {
      page_num
      chapter {
        chapter_num
        title
      }
      objects {
        ...objectProperties
        linked_object {
          ...objectProperties
          audio: file {
            relativePath
          }
        }
        linked_series {
          title
          year
          place
          objects {
            ...objectProperties
          }
        }
        thumbnail: file {
          childImageSharp {
            fluid(maxWidth: 600) {
              presentationHeight
              presentationWidth
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`