import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CardDeck from 'react-bootstrap/CardDeck'
import Thumbnail from "./thumbnail"
import { Link } from "gatsby"

function HomeButton() {
  return (
    <Link to="/" className="btn btn-light btn-sm btn-home">
      <span>‹</span>
    </Link>
  )
}

export default function BookPage({ page }) {
  const objects = page.objects
  const chapter = page.chapter
  const pageNum = parseInt(page.page_num)
  return (
    <Container>
      <Row>
        <Col className="text-center py-4 text-muted">
          <HomeButton />
          {chapter 
            ? (<>
              {chapter.chapter_num 
                ? <span className="text-muted">{`${chapter.chapter_num}. `}</span>
                : null}
              {chapter.title}
              {` ~ `}
              p<span className="d-none d-sm-inline">agina </span>
              {pageNum}
              </>) 
            : `Pagina ${pageNum}`
          }
        </Col>
      </Row>
      <Row className="align-items-center" 
        style={{minHeight: "calc(100vh - 12rem)", paddingBottom: "5rem"}}>
        <Col className="w-100">
          <CardDeck className="justify-content-center">
            { objects.length > 0
              ? objects.map(
                  obj => <Thumbnail object={obj} key={obj.object_id} />
                )
              : <p className="text-muted text-center">
                {`Er staan geen objecten op pagina ${pageNum}.`}
                </p>
            }
          </CardDeck>
        </Col>
      </Row>
    </Container>
  )
}